export const prefix = "";

export const paths = {
  login: prefix + "/",
  home: prefix + "/",
  forgetPassword: prefix + "/esqueceu_a_senha",
  myProperties: prefix + "/meus_imoveis",
  registryReservation: prefix + "/cadastrar_reserva",
  studioManagement: prefix + "/gerenciamento_estudio",
  cleaningAndMaintenance: prefix + "/limpeza_e_manutencao",
  profile: prefix + "/perfil",
  geral: prefix + "/geral",
  general: prefix + "/general",
  detail: prefix + "/detail",
  system: prefix + "/system",
  reservation: prefix + "/reservas",
  registerOwners: prefix + "/proprietarios",
  mangerRegistry: prefix + "/cadastrar_gestor",
  registerServiceProvider: prefix + "/cadastrar_prestadora_de_servicos",
};
